import React from 'react';
import css from './SectionHeroNew.module.css';
import Icon1 from '../../../assets/photos/find-creatorr.png';
import Icon2 from '../../../assets/photos/signup-icon.png';
import subscription from '../../../assets/photos/subscriptions.png';
import { NamedLink } from '../../../components';
import { BsFilePersonFill } from 'react-icons/bs';
import { BsFillCollectionPlayFill } from 'react-icons/bs';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const SectionHeroNew = ({ currentUser }) => {
  const { attributes } = currentUser || {};
  const { profile } = attributes || {};
  const { publicData } = profile || {};
  const { userType } = publicData || {};
  const isBrand = userType === 'brand';
  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.hero}>
          <div className={css.textContainer}>
            <p className={css.smallTextWrap}>
              <span className={css.smallTextFinal}>A marketplace for creators and brands</span>
            </p>
            <h1 className={css.title}>Connect, Create & Grow on the Largest Creator Platform</h1>
            {/* <p className={css.subtitle}>Best Creator Brands and Agencies</p> */}
            <div className={css.heroBtnWrap}>
            <Link
              className={css.heroButton}
              to='/s'
            >
              <span className={css.heroButtonIcon}><img src={Icon1} alt="icon" /></span> Find your perfect creator
            </Link>
            <NamedLink
              name={currentUser ? (isBrand ? 'SearchPage' : 'NewListingPage') : 'SignupPage'}
              className={css.heroButton}
              to={{ userType: 'creator' }}
            >
              <span className={css.heroButtonIcon}><img src={Icon2} alt="icon" /></span> Sign up to be a creator
            </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeroNew;
