import React, { useState } from "react";
import css from './SectionBrandListMob.module.css';
import Brand1 from '../../../assets/photos/trust-log01.png';
import Brand2 from '../../../assets/photos/trust-log02.png';
import Brand3 from '../../../assets/photos/trust-log03.png';
import Brand4 from '../../../assets/photos/trust-log04.png';
import Brand5 from '../../../assets/photos/trust-log05.png';
import Brand6 from '../../../assets/photos/trust-log06.png';
import Brand7 from '../../../assets/photos/trust-log07.png';
import Brand8 from '../../../assets/photos/trust-log08.png';
import Brand9 from '../../../assets/photos/trust-log09.png';
import Brand10 from '../../../assets/photos/trust-log010.png';
import Brand11 from '../../../assets/photos/trust-log011.png';
import Brand12 from '../../../assets/photos/trust-log012.png';
import Brand13 from '../../../assets/photos/trust-log013.png';
import Brand14 from '../../../assets/photos/trust-log014.png';
import Brand15 from '../../../assets/photos/trust-log015.png';
import Brand16 from '../../../assets/photos/trust-log016.png';
import Brand17 from '../../../assets/photos/trust-log017.png';
import Brand18 from '../../../assets/photos/trust-log018.png';
import Brand19 from '../../../assets/photos/trust-log019.png';
import Brand20 from '../../../assets/photos/trust-log020.png';
import Brand21 from '../../../assets/photos/trust-log021.png';
import leftArrow from '../../../assets/photos/next-arrow.png';
import rightArrow from '../../../assets/photos/prev-arrow.png';
import { Carousel } from 'react-responsive-carousel';

const SectionBrandListMob = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleChange = index => {
        setCurrentIndex(index);
    };

    var data = [
        {
            image: Brand1
        },
        {
            image: Brand2
        },
        {
            image: Brand3
        },
        {
            image: Brand4
        },
        {
            image: Brand5
        },
        {
            image: Brand6
        },
        {
            image: Brand7
        },
        {
            image: Brand8
        },
        {
            image: Brand9
        },
        {
            image: Brand10
        },
        {
            image: Brand11
        },
        {
            image: Brand12
        },
        {
            image: Brand13
        },
        {
            image: Brand14
        },
        {
            image: Brand15
        },
        {
            image: Brand16
        },
        {
            image: Brand17
        },
        {
            image: Brand18
        },
        {
            image: Brand19
        },
        {
            image: Brand20
        },
        {
            image: Brand21
        }
    ];

    return (
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.brandWrap}>
                    <div className={css.brnadTitleWrap}>
                        <h2 className={css.brandTitle}>Trusted by</h2>
                    </div>
                    <div className={css.brandLogoContainer}>
                        <Carousel
                            autoPlay={true}
                            showStatus={false}
                            showThumbs={false}
                            showArrows={false}
                            showIndicators={false}
                            infiniteLoop={true}
                            swipeable={true}
                            transitionTime={2000}
                            interval={5000}
                            onChange={handleChange}
                            centerMode={true}
                            centerSlidePercentage={25}
                            className={css.finalSlider}
                        >
                            {
                                data?.map((item, index) => {
                                    return (
                                        <div className={css.brandLogo} key={index}>
                                            <img src={item.image} alt="brand" />
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionBrandListMob;