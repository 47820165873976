import React, { useEffect, useState } from "react";
import css from './SectionCreatorEcoMob.module.css';
import creatorEco1 from '../../../assets/photos/eco1.png';
import creatorEco2 from '../../../assets/photos/eco2.png';
import creatorEco3 from '../../../assets/photos/eco3.png';
import creatorEco4 from '../../../assets/photos/eco2.png';
import { Carousel } from 'react-responsive-carousel';
import leftArrow from '../../../assets/photos/next-arrow.png';
import rightArrow from '../../../assets/photos/prev-arrow.png';

const SectionCreatorEcoMob = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleChange = index => {
        setCurrentIndex(index);
    };
    const text = [
        {
            heading: 'YouTube Introduces New Monetization Features for Creators',
            label: 'TechCrunch',
            date: 'September 20, 2024',
            image: creatorEco1,
            text: (
                <p className={css.text}>
                    YouTube announced the rollout of new monetization tools to help creators diversify their income streams. The features include enhanced shopping integrations, expanded membership options, and the ability for fans to purchase digital goods directly during live streams.
                </p>
            ),
        },
        {
            heading: 'TikTok Expands Creator Fund to Support Emerging Talent',
            label: 'The Verge',
            date: 'August 15, 2024',
            image: creatorEco2,
            text: (
                <p className={css.text}>
                    TikTok has increased its Creator Fund by $200 million to support emerging influencers on the platform. The expansion aims to provide more financial opportunities for creators to produce engaging content and grow their audiences globally.
                </p>
            ),
        },
        {
            heading: 'Instagram Launches Subscription Model for Exclusive Content',
            label: 'Engadget',
            date: 'July 12, 2024',
            image: creatorEco3,
            text: (
                <p className={css.text}>
                    Instagram introduced a new subscription feature that allows creators to offer exclusive content to their followers for a monthly fee. This move provides creators with a new avenue for revenue while giving fans access to subscriber-only stories, live videos, and badges.
                </p>
            ),
        },
        {
            heading: 'Substack Launches New Features to Empower Independent Writers',
            label: 'Food Blogger',
            date: 'June 11, 2024',
            image: creatorEco4,
            text: (
                <p className={css.text}>
                    Substack has introduced a suite of new tools designed to help independent writers grow their subscriber base and monetize their content more effectively. The updates include enhanced analytics, better integration with social media platforms, and new community-building features to foster reader engagement.
                </p>
            ),
        },
    ];
    const renderArrowPrev = (onClickHandler, hasPrev, label) => (
        <button
            type="button"
            onClick={onClickHandler}
            className={css.leftArrow}
            aria-label={label}
            disabled={!hasPrev}
        >
            <img src={leftArrow} alt="Previous" className={css.arrow} />
        </button>
    );

    const renderArrowNext = (onClickHandler, hasNext, label) => (
        <button
            type="button"
            onClick={onClickHandler}
            className={css.rightArrow}
            aria-label={label}
            disabled={!hasNext}
        >
            <img src={rightArrow} alt="Next" className={css.arrow} />
        </button>
    );

    return (
        <div className={css.creatorEcoWrap}>
            <div className={css.container}>
                <div className={css.creatorEcoContainer}>
                    <div className={css.creatorEcoTopCont}>
                        <h2 className={css.creatorEcoHeading}>What’s Happening in the<br /> Creator Economy</h2>
                    </div>
                    <Carousel
                        autoPlay={true}
                        showStatus={false}
                        showThumbs={false}
                        showArrows={true}
                        showIndicators={true}
                        infiniteLoop={true}
                        swipeable={true}
                        transitionTime={2000}
                        interval={5000}
                        onChange={handleChange}
                        renderArrowPrev={renderArrowPrev}
                        renderArrowNext={renderArrowNext}
                        // centerMode={true}
                        // centerSlidePercentage={100}
                        className={css.finalSlider}
                    >
                        {text.map((item, index) => (
                            <div className={css.creatorEcoSlider} key={index}>
                                <div className={css.creatorSliderCont}>
                                    <div className={css.creatorSlideImg}>
                                        <img src={item.image} alt="creatorSlideImg" />
                                    </div>
                                    <div className={css.creatorAuthWrap}>
                                        <div className={css.creatorSlideContWrap}>
                                            <h3 className={css.creatorSlideHead}>{item.heading}</h3>
                                            <div className={css.creatorEcoLabelWrap}>
                                                <p className={css.creatorSlideDate}>{item.date}</p>
                                                <p className={css.creatorSlideLabel}>{item.label}</p>
                                            </div>
                                        </div>
                                        {item.text}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default SectionCreatorEcoMob;