import React from "react";
import css from './SectionLearnGrow.module.css';
import Grow1 from '../../../assets/photos/learn-grow1.png';
import Grow2 from '../../../assets/photos/learn-grow2.png';
import { NamedLink } from "../../../components";
import Arrow from '../../../assets/photos/arrow-right.png';

const SectionLearnGrow = ({ currentUser }) => {
    const { attributes } = currentUser || {};
    const { profile } = attributes || {};
    const { publicData } = profile || {};
    const { userType } = publicData || {};
    const isBrand = userType === 'brand';

    var data = [
        {
            Image: Grow1,
            label: "Webinar",
            title: "Mastering Brand-Creator Collaborations",
            caption: "November 10, 2024, at 3:00 PM EST",
            buttonText: "Register now"
        },
        {
            Image: Grow2,
            label: "Podcast",
            title: "Episode 20: How Authenticity Drives Engagement",
            caption: "November 10, 2024",
            buttonText: "Listen on Spotify"
        }
    ];

    return (
        <div className={css.root}>
            <div className={css.container}>
                <h2 className={css.learnTitle}>Learn and Grow</h2>
                <div className={css.learnGrowWrap}>
                    {
                        data?.map(function (item, index) {
                            return (
                                <div className={css.learnGrowCard} key={index}>
                                    <div className={css.learnGrowImg}>
                                        <img className={css.learnGrowImgFinal} src={item.Image} alt="learn-grow-img" />
                                    </div>
                                    <div className={css.learnGrowContent}>
                                        <span className={index == 0 ? css.learnGrowLabel : css.learnGrowLabelBlue}>{item.label}</span>
                                        <div className={css.learnGrowContWrap}>
                                            <div className={css.learnGrowContInner}>
                                                <h3 className={css.learnGrowTitle}>{item.title}</h3>
                                                <p className={css.learnGrowCaption}>{item.caption}</p>
                                            </div>
                                            <div className={css.learnGrowButtonWrap}>
                                                <NamedLink
                                                    name={currentUser ? (isBrand ? 'SearchPage' : 'NewListingPage') : 'SignupPage'}
                                                    to={{ userType: 'creator' }}
                                                    className={css.learnGrowButton}
                                                >
                                                    {data[index] ? data[index].buttonText : 'Let’s Start'} <span><img src={Arrow} alt="btn-arrow" /></span>
                                                </NamedLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SectionLearnGrow;