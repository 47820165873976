import React from "react";
import css from './SectionRewardCollab.module.css';
import rewardIcon1 from '../../../assets/photos/reward-icon1.png';
import rewardIcon2 from '../../../assets/photos/reward-icon2.png';
import rewardIcon3 from '../../../assets/photos/reward-icon3.png';
import rewardIcon4 from '../../../assets/photos/reward-icon4.png';
import rewardRightImg from '../../../assets/photos/reward-right-img.png';

const SectionRewardCollab = () => {
    return(
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.rewardCollabContWrap}>
                    <div className={css.partnerRightImg}>
                        <img className={css.partnerRightImgFinal} src={rewardRightImg} alt="partner-right-img" />
                    </div>
                    <div className={css.rewardContWrap}>
                        <span className={css.rewardLabel}>For Creators</span>
                        <h2 className={css.rewardHeading}>Transform Your Creativity into Rewarding Collaborations</h2>
                        <div className={css.rewardIconCont}>
                            <div className={css.rewardIconWrap}>
                                <img src={rewardIcon1} alt="reward-icon" />
                            </div>
                            <div className={css.rewardContent}>
                                <h3 className={css.rewardInnerHeading}>Connect with Top Brands</h3>
                                <p className={css.rewardText}>Collaborate on projects that showcase your talent.</p>
                            </div>
                        </div>
                        <div className={css.rewardIconCont}>
                            <div className={css.rewardIconWrap}>
                                <img src={rewardIcon2} alt="reward-icon" />
                            </div>
                            <div className={css.rewardContent}>
                                <h3 className={css.rewardInnerHeading}>Join a Thriving Community</h3>
                                <p className={css.rewardText}>Join a network that supports and elevates your creativity.</p>
                            </div>
                        </div>
                        <div className={css.rewardIconCont}>
                            <div className={css.rewardIconWrap}>
                                <img src={rewardIcon3} alt="reward-icon" />
                            </div>
                            <div className={css.rewardContent}>
                                <h3 className={css.rewardInnerHeading}>Showcase Your Talent</h3>
                                <p className={css.rewardText}>Create a portfolio that highlights what makes you special.</p>
                            </div>
                        </div>
                        <div className={css.rewardIconCont}>
                            <div className={css.rewardIconWrap}>
                                <img src={rewardIcon4} alt="reward-icon" />
                            </div>
                            <div className={css.rewardContent}>
                                <h3 className={css.rewardInnerHeading}>Earn More & Grow More</h3>
                                <p className={css.rewardText}>Increase your earnings through collaborations and referrals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionRewardCollab;